import { createPopper } from '@popperjs/core'

class {
    onCreate() {
        this.state = {
            popper: null
        };
    }
    onMount() {
        this.state.popper = createPopper(this.el.previousElementSibling, this.getComponent("popover").el, {
            placement: "bottom",
            modifiers: [
                {
                    name: "offset",
                    options: {
                        offset: [0, 5]
                    }
                },
                {
                    name: 'arrow',
                    options: {
                        padding: 8,
                        element: this.getEl("arrow")
                    }
                },
                {
                    name: 'preventOverflow',
                    options: {
                        altAxis: true,
                        padding: 8
                    },
                }
            ]
        });

        this.state.popper.update();

        this.update();
    }

    async onUpdate() {
        await this.state.popper.update();
    }
}

style {
    .ui-popover {
        visibility: hidden;
        pointer-events: none;
        font-size: initial !important;
        background-color: var(--oc-gray-7) !important;
        color: white !important;
        position: absolute;
    }

    *:hover + .ui-popover {
        pointer-events: auto;
        visibility: visible;
        z-index: 2;
    }

    .ui-popover-arrow {
        display: block;
        position: absolute;
        bottom: calc(100% - 5px);
        background-color: var(--oc-gray-7);
        width: 10px;
        height: 10px;
        border-radius: 10px;
    }
}

ui-container.ui-popover ...input filled rounded raised padded key="popover"
    div.ui-popover-arrow key="arrow"
    <${input.renderBody}/>

