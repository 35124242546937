style {
    .ui-textbox {
        outline: none;
        border: solid var(--border) 1px;
        border-radius: var(--rounded-radius);
        padding-left: var(--padding-wide) !important;
        padding-right: var(--padding-wide) !important;
        padding-bottom: var(--padding) !important;
        font-family: var(--text-font);
    }
}

ui-element.ui-textbox ...input tag="input" labeled=input.label!=null class=input.class id=input.id type=input.password?"password":"textbox" value=state.value padded fullwidth on-input("pushInput") on-click("handleClick")
ui-label text=input.label small=state.value?true:false