style {
    .ui-button {
        cursor: pointer;
        transition: background-color 0.15s, color 0.15s;
        user-select: none;
        font-family: var(--button-font);
    }

    .ui-button a {
        text-decoration: none;
    }

    .ui-button:hover {
        background-color: var(--secondary-dark);
        color: var(--text-light);
    }

    .ui-button-content {
        display: flex;
        align-items: center;
        color: inherit;
    }

    .ui-button-content > .ui-icon:not(:last-child) {
        margin-right: 8px;
    }

    .ui-button-content > .ui-icon:last-child:not(:first-child) {
        margin-left: 8px;
    }

    .ui-button.disabled {
        background-color: var(--primary-dark);
        cursor: default;
    }

    .ui-button.disabled:hover {
        color: var(--text-dark);
    }
}

ui-element.ui-button ...input title=null on-click(input.disabled?()=>{}:"handleClick") class=input.class id=input.id
    ui-element.ui-button-content tag="a" padded href=input.href||input.src||null
        if(input.icon)
            ui-icon icon=input.icon
        if(input.text)
            ui-text -- ${input.text}
        <${input.renderBody}/>
        if(input.trailingicon)
            ui-icon icon=input.trailingicon
if(input.title!=null)
    ui-popover
        ui-text -- ${input.title}