style {
    .ui-color-select-wrapper {
        position: relative;
    }

    .ui-color-preview {
        position: relative;
        cursor: pointer;
        border-radius: 50%;
        background-color: transparent;
    }

    .ui-color-preview::before {
        padding: var(--padding-wide);
        transition: border 0.5s, margin 0.5s;
        border-radius: 50%;
        background-color: inherit;
        position: absolute;
        top: -5px;
        left: -5px;
        content: "";
        display: block;
        border: 5px solid transparent;
    }

    .active-color::before, .ui-color-preview-wrapper:hover > .ui-color-preview::before {
        /*transform: scale(1.2);*/
        border: 5px solid #ffffffee;
    }

    .ui-color-select {
        position: fixed;
        width: 200px;
        margin: 5% auto; /* Will not center vertically and won't work in IE6/7. */
        left: 0;
        right: 0;
        background-color: #fff;
    }

    .ui-color-select-inner {
        transition: background-color 0.25s;
        padding-bottom: calc(var(--padding) + 8px) !important;
        padding-top: calc(var(--padding-wide) + 10px) !important;
    }

    .ui-color-select-wrapper .ui-label-small {
        transform: translateY(calc((var(--padding-wide) + 3.25em) * -1)) scale(var(--label-small-scale));
    }
}

$ let colorsLists = input.colors||[['#fff', '#aaa', '#888', '#444', '#000']]

div.ui-color-select-wrapper
    if(input.popup)
        ui-element.ui-color-preview ...input padded outlined filled theme={'--primary': state.color, '--primary': state.color} on-click("toggleDialogue")
        ui-container fullscreen hidden=!state.showDialogue on-click("toggleDialogue")
        ui-card.ui-color-select ...input raised hidden=!state.showDialogue
            @header title="Color Select" heading=5
            ui-linear-layout vertical centered padded
                for|colors| of=colorsLists
                    @element
                        ui-linear-layout centered
                            for|color| of=colors
                                @element
                                    ui-element.ui-color-preview padded outlined filled theme={'--primary': color} on-click("selectColor", color)
    else
        ui-linear-layout.ui-color-select-inner.ui-input wrap theme={'--primary': state.color.color+"44"} filled paddedwide ...input
            for|color| of=input.colors||colorsLists[0]
                @element.ui-color-preview-wrapper
                    ui-element.ui-color-preview class={"active-color": state.color.value===color.value} paddedwide filled theme={'--primary': color.color} raised on-click("selectColor", color)
        ui-label label=input.label small=true