style {
    .ui-list-item.selected {
        background-color: var(--secondary);
    }
}

div.ui-list
    ui-linear-layout ...input vertical on-click("handleClick") gutter=input.gutter||input.spacing||"0px"
        for|item, index| of=input.items||[]
            @element ...item
                ui-element.ui-list-item tag="div" class={'selected': input.selected===index} on-click("handleItemClick", item, index)
                    <${item.renderBody}/>