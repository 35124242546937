style {
    .ui-label {
        height: 0px;
        white-space: nowrap;
        width: 0px;
        transform: translateY(calc((var(--padding-wide) + 1.25em) * -1));
        margin-left: var(--padding-wide) !important;
        transition: transform 0.5s, color 0.15s;
        opacity: 0.8;
        pointer-events: none;
        --label-small-scale: 0.75;
        display: block;
    }

    .ui-label-small, .ui-textbox:focus + .ui-label {
        transform: translateY(calc((var(--padding-wide) + 1.6em) * -1)) scale(var(--label-small-scale));
    }

    .ui-button:hover + .ui-label {
        color: var(--text-light);
        opacity: 1;
    }
}

if(input.label||input.text)
    label.ui-label class={"ui-label-small": input.small||false}
        ui-text -- ${input.label||input.text}