style {
    :root {
        --primary: #fff;
        --primary-light: #fff;
        --primary-dark: #f1f3f5;
        --secondary: #a5d8ff;
        --secondary-light: #e7f5ff;
        --secondary-dark: #4dabf7;
        --tertiary: #ffd8a8;
        --tertiary-light: #fff4e6;
        --tertiary-dark: #ffa94d;
        --background-1: #fff;
        --background-2: #444;
        --accent-1: #f1f1f1;
        --accent-2: #aaa;
        --text-dark: #444;
        --text-light: #fff;
        --border: #444;
        --rounded-radius: 4px;
        --padding: 8px;
        --padding-narrow: 4px;
        --padding-wide: 32px;
        --font-size: 1em;
        --font-size-small: 0.75em;
        --font-size-h1: 2em;
        --font-size-h2: 1.5em;
        --font-size-h3: 1.1em;
        --font-size-h4: 1em;
        --font-size-h5: 1em;
        --font-size-h6: 0.9em;
        --text-font: sans-serif;
        --header-font: sans-serif;
        --button-font: sans-serif;
    }

    body {
        display: flex;
        min-height: 100vh;
        width: 100%;
        background-color: var(--primary);
        color: var(--text-dark);
    }

    * {
        padding: 0px;
        margin: 0px;
        box-sizing: border-box;
        font-size: var(--font-size);
    }

    h1 {
        font-size: var(--font-size-h1);
        font-family: var(--header-font);
    }

    h2 {
        font-size: var(--font-size-h2);
        font-family: var(--header-font);
    }

    h3 {
        font-size: var(--font-size-h3);
        font-family: var(--header-font);
    }

    h4 {
        font-size: var(--font-size-h4);
        font-family: var(--header-font);
    }

    h5 {
        font-size: var(--font-size-h5);
        font-family: var(--header-font);
    }

    h6 {
        font-size: var(--font-size-h6);
        font-family: var(--header-font);
    }

    .ui-element.light {
        color: var(--text-light);
    }

    .ui-element.dark {
        color: var(--text-dark);
    }

    .ui-element.filled {
        background-color: var(--primary);
        color: var(--text-dark);
    }

    .ui-element.accent, .ui-element.secondary {
        background-color: var(--secondary);
        color: var(--text-light);
    }

    .ui-element.rounded {
        border-radius: var(--rounded-radius);
    }

    .ui-element.padded {
        padding: var(--padding);
    }

    .ui-element.paddedwide {
        padding: var(--padding-wide);
    }

    .ui-element.paddednarrow {
        padding: var(--padding-narrow);
    }

    .ui-element.centered {
        text-align: center;
    }

    .ui-element.alignleft {
        text-align: left;
    }

    .ui-element.alignright {
        text-align: right;
    }

    .ui-element.fullwidth {
        width: 100%;
    }

    .ui-element.hidden {
        display: none;
    }

    .ui-element.raised {
        box-shadow: 0px 0px 15px rgba(0,0,0,0.1);
    }

    .ui-element.outlined {
        border: 1px solid var(--border);
    }

    .ui-element.labeled {
        padding-top: calc(var(--padding-wide) + 0.25em) !important;
        padding-left: var(--padding-wide) !important;
    }

    .ui-element.fullscreen {
        width: max(100vw, 100%);
        height: max(100vh, 100%);
        position: fixed;
        top: 0px;
        left: 0px;
        background-color: rgba(0,0,0,0.25);
        z-index: 100;
    }
}

$ let style = [input.style, input.theme]

<${input.tag ? input.tag : 'div'} ...input class=state.classes id=input.id style=style on-click("handleClick") on-input("handleInput")>
    <${input.renderBody}/>
</>