style {
    .ui-select {
        padding-right: 16px;
        appearance: none;
    }

    .ui-select-default {
        opacity: 0.5;
    }
}

ui-element.ui-select tag="select" class=input.class id=input.id ...input padded on-input('selectOption') value=state.value
    if(input.includeblank)
        option.ui-select-default label=input.default||" " -- ${input.default||''}
    for|option| of=input.options||[]
        ui-element.ui-select-option selected=state.value==option.value tag="option" ...input key=option.value value=option.value -- ${option.text||option.name||option.value}
ui-label small=true text=input.label
