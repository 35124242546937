style {
    .ui-container {
        display: flex;
        flex-direction: column;
    }

    .ui-container.centered {
        align-items: center;
        place-content: center;
    }

    .ui-container.placecenter {
        place-content: center;
    }

    .ui-container.aligncenter {
        align-items: center;
    }

    .ui-container.fillspace {
        flex: 1;
    }
}

// Check for these keywords in the input and add them to the class list if present
$ const keywords = ["centered", "placecenter", "aligncenter", "fillspace"];
$ let classes = [input.class||null];
$ for(let word of keywords) {
    if(input[word]||false === true) {
        classes.push(word);
    }
}

ui-element.ui-container ...input class=classes id=input.id on-click("handleClick")
    <${input.renderBody}/>