import {navigate} from "../../router.js";

class {
    handleClick(event) {
        navigate(this.input.path);
    }
}

style {
    .router-link {
        display: contents;
    }
}

div.router-link on-click('handleClick')
    <${input.renderBody}/>