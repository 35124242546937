style {
    .ui-date-select-wrapper {
        transition: background 0.25s;
    }

    .ui-date-select-wrapper:hover {
        background-color: var(--secondary);
    }

    .ui-date-preview {
        cursor: pointer;
    }

    .ui-date-select {
        position: fixed;
        margin: 20% auto; /* Will not center vertically and won't work in IE6/7. */
        left: 0;
        right: 0;
        top: 0px;
        background-color: #fff;
        z-index: 100;
        max-width: 325px;
        overflow: hidden;
    }

    .day, .day-header {
        width: 2em;
        transition: background-color 0.25s;
        color: var(--text-dark);
    }

    .day:hover {
        cursor: pointer;
        background-color: var(--secondary-light);
    }

    .clock-wrapper {
        margin-top: 25px;
        margin-bottom: 25px;
        position: relative;
        width: 250px;
        min-height: 250px;
        padding: 25px;
    }

    .clock-minute {
        top: calc(1em - 6px);
        left: calc(50% - 6px);
        position: absolute;
        transform-origin: 50% calc(125px - 1em + 6px);
        width: 6px;
        height: 6px;
        border-radius: 10px !important;
        text-align: center;
    }

    .clock-minute::before {
        content: " ";
        width: 1px;
        position: absolute;
        height: 1px;
        left: 50%;
        top: 50%;
        display: block;
        background-color: #444;
    }

    .clock-number {
        top:0px;
        left:calc(50% - 1em);
        position: absolute;
        transform-origin: 50% 125px;
        width: 2em;
        height: 2em;
        border-radius: 1em !important;
        text-align: center;
    }

    .clock-number > a {
        place-content: center;
    }

    .clock-number-inner {
        top:2em;
        transform-origin: 50% calc(125px - 2em);
    }

    .clock-center {
        width: 14px;
        height: 14px;
        border-radius: 7px;
        background-color: var(--secondary);
        position: absolute;
        top: calc(50% - 7px);
        left: calc(50% - 7px);
    }

    .clock-hand {
        width: 4px;
        height: calc(124px - 2em);
        border-radius: 2px;
        position: absolute;
        top: 2em;
        left: calc(50% - 2px);
        transform-origin: 50% calc(125px - 2em);
        background-color: var( --secondary);
        transition: transform 0.25s;
    }

    .ui-date-fullscreen-overlay {
        z-index: 100;
    }

    .clock-toggle-hour-select {
        position: absolute;
        top: calc(-25px + 8px);
        left: 8px;
    }
}

$ const days = ["S", "M", "T", "W", "Th", "F", "Sa"];
$ const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];

$ let firstDate = new Date(state.curDate.getFullYear(),state.curDate.getMonth(),1);
$ let firstOffset = firstDate.getDay();
$ let lastDate = new Date(state.curDate.getFullYear(),state.curDate.getMonth()+1,0);
$ let numDays = lastDate.getDate();

ui-element.ui-date-select-wrapper ...input
    ui-button on-click("togglePopup") labeled=input.label!=null rounded trailingicon="calendar"
        ui-date.ui-date-preview time=state.includeTime date=state.includeDate milliseconds=state.value
    ui-label text=input.label small=true
    ui-container.ui-date-fullscreen-overlay fullscreen hidden=!state.popup on-click("togglePopup")
    ui-card.ui-date-select ...input raised hidden=!state.popup
        @header title="Date Select" heading=5 padded secondary alignleft
            ui-heading padded
                ui-linear-layout
                    if(state.includeDate)
                        @element
                            ui-date time=false milliseconds=state.value on-click("changeTab", "date")
                    if(state.includeTime)
                        @element
                            ui-date date=false milliseconds=state.value on-click("changeTab", "time")
        if(state.includeDate && state.includeTime)
            ui-linear-layout.tab-buttons filled fillspace fullwidth
                @element fillspace
                    ui-button.tab-button icon="calendar" filled secondary=state.tab!=="date" on-click("changeTab", "date")
                @element fillspace
                    ui-button.tab-button icon="clock" filled secondary=state.tab!=="time" on-click("changeTab", "time")
        if(state.tab==="date")
            ui-linear-layout vertical centered padded
                @element
                    ui-linear-layout
                        @element
                            ui-button icon="chevron-left" rounded on-click("changeMonth",false)
                        @element
                            ui-select value=state.curDate.getMonth().toString() options=months.map((m, i)=>{return {value:i, text:m};}) on-input("setMonth")
                        @element
                            ui-select value=state.curDate.getFullYear() options=Array.from({length: new Date().getFullYear()-1970+1}, (_, i) => {return {value: i + 1970 + 1}}).reverse() on-input("changeYear")
                        @element
                            ui-button icon="chevron-right" rounded on-click("changeMonth",true)
                @element fillspace
                    ui-linear-layout fillspace
                        for|day| of=days
                            @element class="day-header" aligncenter
                                ui-heading heading=3 -- ${day}
                    for|week| from=0 to=5
                        ui-spacer padding="4px"
                        ui-linear-layout fillspace
                            for|d| from=1 to=7
                                $ let day = week * 7 + d - firstOffset;
                                if(day <= numDays && day > 0)
                                    @element class="day" aligncenter rounded secondary=(state.curDate.getDate()===day&&state.curDate.getFullYear()===state.date.getFullYear()&&state.curDate.getMonth()===state.date.getMonth())
                                        ui-element on-click("setDate", state.curDate.getMonth(), day, state.curDate.getFullYear())
                                            ui-text padded -- ${day}
                                else
                                    @element class="day-header"
                                        ui-text padded
        else-if(state.tab==="time")
            ui-element.clock-wrapper padded fullwidth fillspace centered
                ui-button.clock-toggle-hour-select rounded title=(state.setHour?"Set minutes":"Set hours") icon="refresh-cw" on-click("toggleSetHours")
                div.clock-center
                $ let r = (state.setHour?state.curDate.getHours():state.curDate.getMinutes()) * (state.setHour?360/12:360/60);
                div.clock-hand style={transform: 'rotate('+r+'deg)'}
                if(state.setHour)
                    for|i| from=-1 to=23
                        ui-button.clock-number class={"clock-number-inner": i>11} text=i.toString() secondary=state.curDate.getHours()===i rounded style={transform: 'rotate('+(i * 360/12)+'deg)'} on-click("changeHour", i)
                else
                    for|i| from=-1 to=59
                        ui-button.clock-minute class={"clock-number": i%5===0} text=(i%5===0?i.toString():"") secondary=state.curDate.getMinutes()===i rounded style={transform: 'rotate('+(i * 360/60)+'deg)'} on-click("changeMinute", i)
