class {
    onCreate(input) {
        this.state = {
            collapsed: input.collapsed
        }
    }

    onInput(input) {
        this.state.collapsed = input.collapsed;
    }

    handleItemClick(event) {
        this.emit("itemclick", event);
    }

    toggleCollapsed(event) {
        this.state.collapsed = !this.state.collapsed;
    }
}

style {
    .ui-sidebar {
        background-color: var(--primary);
        min-width: 300px;
        position: relative;
        min-height: max(100%, 100vh);
    }

    .ui-sidebar-inner {
        flex: 1;
        position: sticky;
        top: 0px;
        left: 0px;
    }

    .ui-sidebar.collapsed {
        min-width: unset;
    }

    .ui-sidebar.sidebar-overlay {
        position: fixed;
        top: 0px;
        left: 0px;
        z-index: 500;
        transition: 0.25s left;
    }

    .ui-sidebar.sidebar-hidden {
        left: -100%;
    }

    .sidebar-collapse-button {
        height: 100%;
        padding: var(--padding-narrow) !important;
    }
}

ui-container.ui-sidebar class={'collapsed': state.collapsed, "sidebar-overlay": input.overlay, "sidebar-hidden": input.hidden} 
    ui-linear-layout.ui-sidebar-inner gutter="0px"
        @element fillspace
            ui-list selected=null||input.selected on-itemclick("handleItemClick")
                for|item, index| of=input.items||[]
                    @item class=item.class id=item.id ...item
                        ui-button class=item.innerclass padded text=!state.collapsed?item.text||null:(item.icon?'':(item.text&&item.text.length>0?item.text[0]:'')) icon=item.icon
        @element
            if(input.collapsible)
                ui-button.sidebar-collapse-button icon=state.collapsed?'chevron-right':'chevron-left' on-click("toggleCollapsed")