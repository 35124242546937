style {
    .ui-combobox-wrapper {
        position: relative;
    }

    .ui-combobox-options {
        position: absolute;
        top: 100%;
        left: 0px;
        width: 100%;
        font-size: var(--font-size);
        box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.15);
        transition: opacity 0.25s;
        z-index: 300;
        overflow-x: hidden;
        overflow-y: auto;
        max-height: 33vh;
    }

    .ui-comobox-toggle-button {
        flex: 1;
        place-content: center;
        align-items: center;
    }

    .ui-combobox-option {
        font-size: initial !important;
    }

    .ui-combobox-option > .ui-button:hover {
        background-color: var(--secondary-light);
        color: var(--text-dark);
    }

    .ui-combobox-clear-button-wrapper {
        position: absolute;
        right: var(--padding);
    }

}

static function escapeRegExp(string) {
    return string.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
}

ui-element.ui-combobox-wrapper
    ui-linear-layout.ui-combobox-controls gutter="0px" aligncenter
        @element fillspace
            ui-textbox key="comboboxinput" value=state.value ...input on-input("pushInput") on-click("preHandleClick")
        if(state.value != null && state.value.length>0)
            @element.ui-combobox-clear-button-wrapper
                ui-button.ui-combobox-clear-button rounded icon='x' on-click("clearInput")
        /*@element
            ui-button.ui-comobox-toggle-button icon=state.displayOptions?"x":"chevron-down" on-click("toggleDropDown")*/
    ui-linear-layout.ui-combobox-options vertical filled hidden=!state.displayOptions rounded gutter="0px"
        if(input.first && input.first.renderBody)
            @element.ui-combobox-option ...input.first
                <${input.first.renderBody}/>
        for|option| of=(input.options||[]).filter((el) => {return ((el.search||el.name||el.text||el.value||el).toLowerCase().search(escapeRegExp(state.value||"").toLowerCase()) >= 0)})||[]
            @element.ui-combobox-option
                if(input.option != null && input.option.renderBody != null)
                    ui-button key=option value=option on-click("onOptionSelect", (option.name||option.text||option.value||option), option)
                        <${input.option.renderBody} option=option/>
                else
                    ui-button key=option value=option on-click("onOptionSelect", (option.name||option.text||option.value||option), option) -- ${(option.name||option.text||option.value||option)}
        if(input.last && input.last.renderBody)
            @element.ui-combobox-option ...input.last
                <${input.last.renderBody}/>